import {useRef, useEffect} from 'react';
import {useCallback} from 'react';
import {Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {CloseIcon} from '@chakra-ui/icons'
import {debounce} from "services/helpers";



export function InputFilter({onSearch, text, ...rest}) {

	const ref = useRef();

	useEffect(() => {
		// if (text) {
		ref.current.value = text;
		// }
	}, [text])


	function handleInputChange(e) {
		const value = e.target.value;
		// setInputText(value);
		debounceSearch(value)
		// handleSearch(value);
	}

	const debounceSearch = useCallback(debounce(handleSearch, 300), []);

	function handleSearch(text) {
		onSearch(text);
	}
	function clearValue() {
		onSearch('');
	}

	return (
		<InputGroup sx={{
			width: ['100%', '100%', '100%'],
			flex: ['0 0 auto', '1 0 0%', '1 0 0%'],
		}}>
			<Input placeholder='Search...' {...rest} ref={ref} onChange={handleInputChange} sx={{
				':focus-visible': {
					border: '1px solid #fff !important',
					boxShadow: '0 0 0 1px #fff !important',
				},
			}} />
			{text && <InputRightElement>
				<CloseIcon color='#fff' onClick={clearValue} />
			</InputRightElement>}
		</InputGroup>
	)
}
import {useModalContext} from "context/ModalContext";


export function useModalYoutube() {
	const {onOpen: onOpenOriginal, ...rest} = useModalContext();

	function onOpen(youtubeId) {
		onOpenOriginal('', youtubeId);
	}

	return {onOpen, ...rest}
}
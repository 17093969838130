// FilterBar.js

import {InputFilter} from './InputFilter';
import {TechDropdown} from './TechDropdown';
import {Flex} from '@chakra-ui/react';

function FilterBar({onFilter, filterVals, dropdownOptions}) {
	const {selText, selTechStack} = filterVals;

	const handleSearch = (newText) => {
		onFilter({selText: newText});
	};

	const handleDropdownChange = (options) => {
		onFilter({selTechStack: [...options]});
	};

	return (
		<Flex className="filterBar" alignItems="center" gap={4} width="100%" flexWrap='wrap'>
			<InputFilter onSearch={handleSearch} text={selText} />
			<TechDropdown options={dropdownOptions} selected={selTechStack} onChange={handleDropdownChange} />
		</Flex>
	);
}

export default FilterBar
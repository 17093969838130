import React from 'react';
import './Loader.css';
import {useAppContext} from "context/AppContext";
import FullScreenSection from "../FullScreenSection";
import {useEffect, useState} from 'react';
import useAnimationProgress from 'hooks/useAnimationProgress';



export function Loader({children}) {

	const [showLoader, setShowLoader] = useState(true);
	const response = useAppContext();
	const [progress, setLoaderRef] = useAnimationProgress();

	// console.log(progress);


	useEffect(() => {
		const {resolved, rejected} = response.status;
		if (progress == 100 && (resolved || rejected)) {
			setShowLoader(false);
		}
	}, [progress, response.status]);


	return (
		showLoader ? (
			<FullScreenSection
				justifyContent="center"
				alignItems="center"
				isDarkBackground
				backgroundColor="#2A4365"
			>
				<div className="Loader" ref={(node) => setLoaderRef(node, '::before')}></div>
			</FullScreenSection>
		) : (
			<div className='animateContent'>
				{children}
			</div>
		)
	)
}
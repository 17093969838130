import {HStack} from '@chakra-ui/react'
// import {useBreakpoints} from 'hooks/useBreakpoints';
// import {useRenderCounter} from 'hooks/useRenderCounter';
// import React, {useState, useMemo, memo} from 'react'




function Menu({children}) {

	// const renderCount = useRenderCounter(0);
	// const breakpointsState = useBreakpoints(breakpoints)

	// console.log(breakpointsState);

	return (
		<>
			{/* {renderCount} */}
			<HStack
				as="nav"
				spacing={{base: 6, md: 8, lg: 8}}>
				{children}
			</HStack>
		</>
	)
}
// eslint-disable-next-line no-func-assign
// Menu = memo(Menu);

export default Menu;
import {useCallback, useEffect, useRef, useState} from "react";
import type {ElementConfig} from "types/useAnimationProgress";


function useAnimationProgress() {
	const [progress, setProgress] = useState(0);
	const elementConfig = useRef<ElementConfig>({element: null, pseudoSel: ''});

	useEffect(() => {
		let animationFrameId: number = 0;

		const checkAnimationProgress = () => {
			const {element, pseudoSel} = elementConfig.current;
			if (element) {
				const styles = getComputedStyle(element, pseudoSel);
				const progressVal = parseInt(styles.getPropertyValue('--animation-progress'), 10);

				if (progress !== progressVal) {
					setProgress(progressVal);
				}

				animationFrameId = requestAnimationFrame(checkAnimationProgress);
			}
		};

		cancelAnimationFrame(animationFrameId);
		if (elementConfig.current.element) {
			animationFrameId = requestAnimationFrame(checkAnimationProgress);
		}

		return () => {
			cancelAnimationFrame(animationFrameId);
		};
	}, [progress]);
	// }, [elementConfig.current, progress]);

	const setRef = useCallback((node: HTMLElement | null, pseudoSel: string = '') => {
		if (node !== elementConfig.current.element || pseudoSel !== elementConfig.current.pseudoSel) {
			elementConfig.current = {element: node, pseudoSel};
		}
	}, []);

	return [progress, setRef];
}

export default useAnimationProgress;
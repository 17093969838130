import {useRef, useState} from "react";
import {Heading, Image, Text, VStack, AspectRatio, Link} from "@chakra-ui/react";
import {FaYoutube, FaCirclePlay, FaRegCirclePlay} from "react-icons/fa6";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import useDetectOverflow from "hooks/useDetectOverflow";
import HtmlContent from "./HtmlContent";
import {useModalYoutube} from "hooks/useModalYoutube";
// import {motion} from "framer-motion";

// import {
// 	Modal,
// 	ModalOverlay,
// 	ModalContent,
// 	ModalHeader,
// 	ModalFooter,
// 	ModalBody,
// 	ModalCloseButton,
// } from '@chakra-ui/react'


// const MotionVStack = motion(VStack);

const Card = ({title, subheading, description, coverImg, technologies, link, youtubeVideoId}) => {

	const ref = useRef(null);
	const descHasOverflow = useDetectOverflow(ref, 300);

	// const imageLink = youtubeVideoId ? `https://www.youtube.com/watch?v=${youtubeVideoId}` : link;
	const videoLink = youtubeVideoId ? `https://www.youtube.com/watch?v=${youtubeVideoId}` : null;
	// eslint-disable-next-line no-undef
	const imageSrc = coverImg ? coverImg : (youtubeVideoId ? `https://img.youtube.com/vi/${youtubeVideoId}/sddefault.jpg` : require('assets/imgs/placeholder-image.png'));

	const {onOpen} = useModalYoutube();

	// const [isOpen, setIsOpen] = useState(false);


	return (
		<VStack className="card"
			borderRadius='10px'
			bg='#fff'
			color='#222'
			textAlign='left'
			spacing={0}
			_hover={{
				shadow: '0 2px 12px 1px rgba(0, 0, 0, 0.3)',
				// 'img': {transform: 'scale(0.9)'}
			}}
			// transition='all 0.4s ease-out'
			initial={{opacity: 0, scale: 0.95}}
			animate={{opacity: 1, scale: 1}}
			transition={{duration: 0.4, ease: "easeInOut"}}
		>
			<AspectRatio w="100%" ratio={16 / 9}>
				{imageSrc && (
					// imageLink ? (
					//   <Link href={imageLink} target='_blank' rel="noopener noreferrer" borderTopLeftRadius='10px' borderTopRightRadius='10px'>
					//     <Image src={imageSrc} alt={title} objectFit='cover' />
					//   </Link>
					// ) : (
					<Image src={imageSrc} alt={title} objectFit='cover' borderTopLeftRadius='10px' borderTopRightRadius='10px' />
					// )
				)}
			</AspectRatio>
			<VStack p='20px' spacing='15px' align='flex-start' borderTop='1px solid #e2e2e2' width='100%'>

				<Heading textAlign='left' size='md' color='#2c333d'>
					{/* <span dangerouslySetInnerHTML={{__html: title}} /> */}
					<HtmlContent as='span' html={title} />
				</Heading>

				<Heading textAlign='left' size='xs' fontWeight={600} color='#48515f'>{subheading}</Heading>

				<Text color='#677489' fontSize='14px' marginTop={-3}>
					Tech stack: {technologies.map((tech, idx) => {
						return (
							<span key={idx}>
								<span style={{}}>{tech.label}</span>
								{idx != technologies.length - 1 ? <>, </> : null}
							</span>
						)
					})}
				</Text>

				<Text ref={ref} color='#677489' fontSize='15px' style={{maxHeight: '157.5px', overflow: 'auto', paddingRight: descHasOverflow ? '20px' : '0'}}>
					<HtmlContent as='span' html={description} />
				</Text>

				<Text color='#677489' fontSize='15px'>
					{link && <a textDecoration='underline' href={link} target='_blank' rel="noopener noreferrer">Project</a>}
					{/* {videoLink && <> {link && <>|</>} <a textDecoration='underline' href={videoLink} target='_blank' rel="noopener noreferrer">Video</a></>} */}
					{videoLink && <> {link && <>|</>} <Link textDecoration='underline' onClick={() => onOpen(youtubeVideoId)}>Video</Link></>}
				</Text>

				{/* <Text>See more <FontAwesomeIcon icon={faArrowRight} size="1x" /></Text> */}

				{/* {youtubeVideoId &&
					<div className="video-container" data-id={youtubeVideoId} onClick={() => onOpen(youtubeVideoId)}>
						<div className="iframe-container">
							<div className="video-iframe"></div>
						</div>
						<div className="video-play">
							<img src={`https://img.youtube.com/vi/${youtubeVideoId}/sddefault.jpg`} alt="" />
							<div className="icon">
								<FaRegCirclePlay size={50} color="#eee" />
							</div>
						</div>
					</div>
				} */}

			</VStack>
		</VStack >
	);
	// return null;
};

export default Card;

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Select, components} from 'chakra-react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import {debounce} from 'services/helpers';



const CustomValueContainer = ({children, ...props}) => {
	const [values, input] = children;
	const [overflowing, setOverflowing] = useState(false);
	const ref = useRef(null);

	// console.log(input);

	const checkOverflow = useCallback(() => {
		if (ref.current) {
			setOverflowing(ref.current.scrollHeight > ref.current.clientHeight)
		}
	}, []);


	// run everytime a value is picked or menu is closed
	useEffect(() => {
		checkOverflow()
	})

	// also run on window resize
	useEffect(() => {
		const debouncedCheckOverflow = debounce(checkOverflow, 150);

		checkOverflow();

		window.addEventListener('resize', debouncedCheckOverflow);
		return () => {
			window.removeEventListener('resize', debouncedCheckOverflow);
		};
	}, []);

	// Clone the input component with modified styles
	// const modifiedInput = React.cloneElement(input, {
	// 	style: {
	// 		...input.props.style,
	// 		flex: '0 0 auto',
	// 		border: '1px solid red'
	// 	}
	// });


	return (
		<components.ValueContainer {...props}>
			<div
				className='custom-valuecontainer'
				ref={ref}
				style={{
					height: '28px',
					width: '100%',
					display: 'flex',
					flexWrap: 'wrap',
					overflow: 'hidden',
					paddingRight: '10px',
					alignItems: 'center'
				}}
			>
				{input}
				{values}
				{overflowing && <div style={{
					position: 'absolute',
					right: '2px',
					marginTop: '2px'
				}}>
					<FontAwesomeIcon icon={faArrowDown} />
				</div>}
			</div>
		</components.ValueContainer>
	);
};

export function TechDropdown({options, selected, onChange, ...rest}) {
	return (
		<Select
			tagVariant="solid"
			isMulti
			isSearchable={true}
			components={{ValueContainer: CustomValueContainer}}
			hideSelectedOptions={false}
			// isOptionDisabled={(option) => {
				// return selected.some((val) => val.value == option.value)
			// }}
			// menuIsOpen={true}
			closeMenuOnSelect={false}
			placeholder="Tech stack"
			className="chakra-react-select"
			classNamePrefix="chakra-react-select"
			options={options}
			value={selected}
			onChange={onChange}
			chakraStyles={{
				placeholder: (provided) => ({
					...provided,
					color: '#fff',
				}),
				container: (provided) => ({
					...provided,
					width: '100%',
					flex: '1 0 0%'
				}),
				clearIndicator: (provided) => ({
					...provided,
					color: '#fff',
				}),
				dropdownIndicator: (provided) => ({
					...provided,
					p: 0,
					w: '40px',
					color: '#fff',
					backgroundColor: 'transparent',
				}),
				control: (provided, state) => ({
					...provided,
					color: '#fff',
					borderBottomLeftRadius: state.menuIsOpen ? 0 : 'md',
					borderBottomRightRadius: state.menuIsOpen ? 0 : 'md',
					_focusVisible: {
						borderColor: '#fff',
						boxShadow: '0 0 0 1px #fff',
					},
				}),
				valueContainer: (provided) => ({
					...provided,
					height: '32px',
					alignItems: 'flex-start',
				}),
				menu: (provided) => ({
					...provided,
					p: 0,
					bg: 'transparent', // Background color of the menu
					borderRadius: '6px',
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					shadow: `0 0 15px 0px rgba(0, 0, 0, 0.2)`,
					border: '1px solid #fff',
					overflow: 'hidden',
				}),
				menuList: (provided) => ({
					...provided,
					m: 0,
					p: 0,
					shadow: `0 0 0 1px #fff`,
					border: '0px solid #fff',
					borderRadius: 0,
					// overflow: 'hidden',
				}),
				option: (provided, state) => {

					// console.log(state.children, state);

					return {
						...provided,
						fontSize: '14px', // Set font size to 10px
						// backgroundColor: state.isSelected ? 'blue' : 'red',
						bg: state.isSelected
							? '#14532d !important'
							: state.isFocused
								? '#14532d'
								: '#fff',
						color: state.isSelected
							? 'white'
							: state.isFocused
								? '#fff'
								: '#000',
						_hover: {
							bg: '#257d48 !important',
							color: '#fff',
						},
						opacity: state.isDisabled ? 0.5 : 1,
						cursor: state.isDisabled ? 'not-allowed' : 'pointer',
					}
				},
				multiValue: (provided) => ({
					...provided,
					bg: '#eee', // Background color of selected options (pills)
					borderRadius: '4px',
					padding: '2px 8px',
					fontSize: '12px', // Set font size for selected options
					border: '0px solid #fff',
					shadow: `none`,
					outline: 'none',
				}),
				multiValueLabel: (provided) => ({
					...provided,
					color: '#333', // Text color of selected options
					borderWidth: 0,
				}),
				multiValueRemove: (provided) => ({
					...provided,
					color: '#777', // Color of the remove icon
					opacity: 0.9,
					borderRadius: 0,
					_hover: {
						color: '#222',
					},
				}),
				input: (provided) => ({
					...provided,
					// flex: '0 0 auto'
				}),
			}}
			{...rest}
		/>
	);
}

// theme.js
import {extendTheme} from "@chakra-ui/react";

const theme = extendTheme({
	components: {
		// ModalOverlay: {
		// 	baseStyle: {
		// 		background: 
		// 	}
		// },
		Input: {
			baseStyle: {
				field: {
					_placeholder: {
						color: '#fff', // Change this to your desired color
					},
					// _focusVisible: {
					// 	border: '1px solid #fff !important',
					// 	boxShadow: '0 0 0 1px #fff !important',
					// 	zIndex: 1,
					// },
				},
			},
		},
	},
});

export default theme;
